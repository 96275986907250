
.events {
  background-color: #1b182b;
  padding-bottom: 4em;
  padding-top: 10em;
  padding-left: 1em;
  padding-right: 1em;
}

.events h2 {
font-size: 32px;
font-weight: 700;
margin-bottom: 50px;
text-align: left;
color: #fff;
}

.events h2 span{
  color: #A992FF;
}

.prices button {
  width: 100%;
  border: 1px solid #7d5fd2;
  background-color: #7d5fd2;
  color: #fff;
  font-size: 13px;
  padding: 8px 15px;
  border-radius: 5px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
}

.prices button a{
  color:#fff;
}

.event_box{
  background-color: #211e32;
  border: 1px solid #2C293C;
  border-radius: 5px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.date h4 ,  .Info h4 {
color: #fff;
font-size: 14px;
font-weight: 600;
margin-bottom: 0;
margin-top: 0;
}

.t1 img{
width: 15px;
}

.date{
border-right: 2px solid #2c293c;
margin-right: 3%;
width: 10%;
}

.Info
{
width: 40%;
margin-right: 3%;
padding-left: 1em;
}

.time{
margin-right: 3%;
width: 20%;
}

.prices{
width: 20%;
}

.prices button a:hover{
 text-decoration:none;
}

.pad10{
padding-left: 6px;
padding-right: 6px;
}

.col-lg-12 {
 width: 100%;
}

.date {
width:10%;
}

.search_results .prices {
width: 12%;
}

.search_results .t2 {
width:93px;
}

.search_results h3{
font-size: 16px;
font-weight: 700;
text-align: left;
color: #fff;
}

.tags {
margin: 0;
margin-bottom: 0px;
padding: 0;
display: flex;
margin-bottom: 22px;
}

.tags li{
list-style: none;
background-color: #211E32;
border: 1px solid #2C293C;
border-radius: 9px;
font-size: 13px;
color: #7B7794;
font-weight: 600;
padding: 5px 13px;
margin-right: 7px;
}

.tags img{
width: 17px;
margin-right: 3px;
}

.search_results h2 {
margin-top: 72px !important;
margin-bottom: 20px !important;
margin: 0 !important;
}

.search_results .date {
width: 5%;
}
.second-heading{
  color: #fff !important;
  font-size: 24px !important;
}
.first-heading{
  color: #fff !important;
  font-size: 32px !important;
}
h3{
  color: #fff !important;
  font-weight: 700;
}



.padding-search  {
padding-left: 8% !important;
padding-right: 8% !important;
}
h3.eventes-heading {
  padding: 10px;
}

@media (max-width:1120px) {
.search-div input
{
  font-size: 13px;
  padding-left: 0px;
}
}

@media (max-width:1200px) {
.search_results .time{
  width: 23%;
}

.date h3, .Info h3
{
  font-size: 12px;
}

.date_1 {
  // width: 23%;
}

.search_results .prices {
  width: 20%;
}
}

@media (max-width:1000px) {
.search-div input{
  font-size:12px;
}


}


@media (max-width:900px) {
#events .container, #events .container-md, #events .container-sm {
  max-width: 100%;
}
}

@media (max-width:768px) {

  .events {
    padding-top: 5em ;
  }

  .date{
    border-right: none !important;
    margin-right: 3%;
    width: 10%;
    }

.events h2{
  font-size: 25px;
}
 
.event_box{
  flex-wrap: wrap;
}

.Info{
  width: 87%;
    margin-right: 0;
    margin-bottom: 6px;
}

.time{
  margin-right: 0%;
  margin-left: 0% !important;
  width: 50%;
}

.prices {
  width: 50%;
  text-align: right;
}

.prices button{
  padding: 6px 7px;
  font-size: 12px;
  width: auto;
}

.t2, .t1{
  padding: 4px 8px;
  width: 97px !important
}

.date h3, .Info h3{
  font-weight: 700;
}

.search_results .date {
  width: 7%;
}

.search_results .Info {
  width: 85%;
  margin-bottom: 10px;
}

.date_1, .search_results .time, .search_results .prices {
  width: 33%;
}

.search_results .t2 {
  width: 96px !important;
}
}

@media (max-width:570px) {
.date_1
{
  // width: 40%;
}

.search_results .date {
  width: 10%;
}

.search_results .time, .search_results .prices {
  width:30%;
}
}

@media (max-width:470px) {
  .t2, .t1{
    font-size: 12px;
  }

  .search_results .t2 {
    margin-right: 5px;
  }
}

 
#sea-list-disclosure-bottom{
  display: none;
}
