.welcome-section {
  min-height: 400px;
  padding: 25px 0px 0px 0px;
  }
  
  .login-slider-image{
      width:50%;
  }
  
  .login-section{
      width: 400px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10em;
      text-align:center;
  }
  
  .signup .login-section {
      margin-top:6em;
  }
  
  .margin-left-4p{
      margin-left: -4px;
  }
  
  .white-fade{
      color:#fff9;
  }
  
  .login-container .welcome-section .login-slider-image
  {
  margin-bottom: -3px;
  }
  
  .height-45{
      height: 45px;
  }
  
  .progress
  {
  top: 11px;
  margin-left: 20px;
  margin-right: 10px;
  }
  
  .form-controls{
      font-weight:500;
      font-size: 13px;
      color: #fff;
      width: 100%;
      border: 2px solid #3B384B;
      border-radius: 3px;
      padding:10px;
      background-color: #302D41;
      margin-top: 6px;
  }
  
  .form-controls:focus{
      outline:none;
  }
  
  .button {
      background: #4182CB;
      border: 1px solid #4182CB;
      color: #fff;
      width: 100%;
      border-radius: 4px;
      font-size: 15px;
      font-weight: 600;
      padding: 8px 19px;
      text-align: left;
  }

  .button i{
  float: right;
  }
  
  .logo-login {
      width: auto;
  }
  
  .ptext{
  font-size: 14px;
  color: #86888a;
  text-align: left;
  padding-top: 7px;
  font-weight: 600;
text-align: center;
padding-top: 2em;
  }
   
  .ptext a{
      color:#A992FF;
      text-decoration: none;
      font-weight: 600;
  }
  
  .login-box {
      text-align: left;
      background-color: #211E32;
      border: 1px solid #2C293C;
      border-radius: 10px;
      margin-top: 20px;
      padding: 25px 35px;
  }

  .login-section h2{
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  }

  .login-section p{
  color: #7B7794;
  font-size: 14px;
  font-weight: 500;
  }

  .login-section lable{
  color: #7B7794;
  font-size: 14px;
  font-weight: 600;
  }
   
  .margin-bottom-15
  {
  margin-bottom: 15px;
  }

  .margintop20{
      margin-top: 20px;
  }
.signup-text {
    color: #A992FF;
    cursor: pointer;
}