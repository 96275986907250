/* .footer-links-new {
  
}
*/

.footer-links-new {
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;
}

.footer-links-new h3 {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  padding-bottom: 0.5rem !important;
  color: #8244dc !important;
  font-weight: 700 !important;
}

.footer-links-new ul {
  list-style: none;
  padding: 0;
  margin: 0;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;
}
.footer-links-new li {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.footer-links-new li:hover {
  color: #bea6e1 !important;
}

.footer-links-new a {
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
.bg-footer {
  background-color: #e6e6e6;
}
