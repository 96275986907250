


.events h2 {
font-size: 27px;
font-weight: 700;
margin-bottom: 50px !important;
text-align: center;
color: #fff;
}

.events h2 span{
  color: #A992FF;
}

.prices button {
  width: 100%;
  border: 1px solid #7d5fd2;
  background-color: #7d5fd2;
  color: #fff;
  font-size: 13px;
  padding: 8px 15px;
  border-radius: 5px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
}

.prices button a{
  color:#fff;
}

.event_box{
  background-color: #211e32;
  border: 1px solid #2C293C;
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 9px;
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  cursor: pointer;
}

.date h3 ,  .Info h3 {
color: #fff;
font-size: 14px;
font-weight: 600;
margin-bottom: 0;
margin-top: 0;
}

.t1 img{
width: 15px;
}

.date{
border-right: 2px solid #2c293c;
margin-right: 3%;
width: 10%;
display: flex;
flex-direction: column;
align-items: center;
}

.Info
{
width: 40%;
margin-right: 3%;
}

.time{
margin-right: 3%;
width: 20%;
}

.prices{
width: 20%;
}

.prices button a:hover{
 text-decoration:none;
}

.pad10{
padding-left: 6px;
padding-right: 6px;
}

.col-lg-12 {
 width: 100%;
}

.date {
width:10%;
}


.search_results .Info{
 width: 29%;
}

.search_results .prices {
width: 12%;
}

.date_1{
// width: 14%;
}

.search_results .t2 {
width: 95px;
}

.search_results h5{
font-size: 16px;
font-weight: 700;
text-align: left;
color: #fff;
}

.tags {
margin: 0;
margin-bottom: 0px;
padding: 0;
display: flex;
margin-bottom: 22px;
cursor: pointer;
height: 33px;
}

.tags li{
list-style: none;
background-color: #211E32;
border: 1px solid #2C293C;
border-radius: 9px;
font-size: 13px;
color: #7B7794;
font-weight: 600;
padding: 5px 13px;
margin-right: 7px;
}

.tags img{
width: 17px;
margin-right: 3px;
}

.search_results h2 {
margin-top: 50px;
margin-bottom: 20px;
}

.search_results .date {
width: 5%;
}

.search_results .time {
width:29%;
}

.padding-search  {
padding-left: 8%;
padding-right: 8%;
}
.t2{
  color: #4D9EFA !important;
 background:rgba(77, 158, 250, 0.20);
  }
 
  .t2, .t1{
  padding: 5px 3px;
   border-radius: 5px;
   margin-top: 0;
   font-size: 13px;
   font-weight: 600;
   margin: 0;
     margin-top: 0px;
   width: 91px;
   margin-top: 4px;
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   align-items: center;
  }
  
  .t1 {
   color: #A992FF !important;
   background-color: #a992ff33;
  }

  .date p , .Info p , .product_left p{
    color:rgba(123, 119, 148, 1);
    font-size: 13px;
 font-weight: 600;
 margin: 0;
  }
  .no-records {
    font-size: 20px;
    color: #fff;
    text-align: center;
}
.main-loader{
  margin: 0 auto;
}
.search-city-name-trending h5{
  color: #7B7794;
}
h5.search-city-name-trending {
  color: #7B7794;
  padding-top: 5px;
}

h2.wow.section-heading {
  text-align: center;
}

.unviel_infor{
 margin-top:10em;
 margin-bottom: 1em;
}

.unviel_infor h1{
  color:#fff; 
  font-size: 25px;
  margin-top: 17px;
  margin-bottom: 17px;
  font-weight: 700;
}

.unviel_infor p{
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}

.unviel_infor span{
color:A992FF;
}
