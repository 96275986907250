.navigation.fixed-top{
  background-color: #2C293C;
  border-radius: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

::-ms-input-placeholder { /* Edge 12-18 */
  color:#fff;
  opacity: 1;
}

::placeholder {
  color:#fff;
  opacity: 1;
}

.search-div input::-moz-placeholder{
  color:#fff;
}

.search-div input::placeholder {
  color:#fff;
  opacity: 1;
}

.main-intro-search-bar::placeholder {
  color:#7B7794;
  opacity: 1;
}

.main-intro-search-bar::-webkit-input-placeholder {
  color:#7B7794 !important;
}
.main-intro-search-bar:-moz-placeholder {
  color:#7B7794!important;
}
.main-intro-search-bar:-ms-input-placeholder{
  color:#7B7794!important;
}
.header-search-bar {
  padding: 12px 0;
}
.header-search-bar::-webkit-input-placeholder {
  color:#7B7794 !important;
}
.header-search-bar:-moz-placeholder {
  color:#7B7794!important;
}
.header-search-bar:-ms-input-placeholder{
  color:#7B7794!important;
}

 

.container {
  padding-left: 0;
  padding-right: 0;
  max-width: 1350px;
}

.navbar > .container, .navbar > .container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.nav > li
{
  padding-top: 11px;
}

.navigation.fixed-top .navbar-collapse {
  height: auto;
  padding:0;
  position: relative;
  display: flex !important;
  flex-grow: 1;
}

.float-right{

  float: right;
}

.navbar-brand {
  height: 50px;
  padding: 0 5px !important; 
  font-size: 18px;
  line-height: 11px;
  width:20%;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
}

.menu-right {
  width:90%;
}

.width100{
  width:100%;
}

.navigation .logo {
  width: auto;
  display: block;
  height:auto;
}

.navbar-expand-md .navbar-nav{
  flex-direction: row;
}

.navbar-nav {
  position: relative;
  display: flex;
}

.navigation .nav-link
{
  padding-left: 2.25rem !important;
  text-transform: uppercase;
  color: #fff !important;
font-size: 15px;
line-height: 20px;
font-weight: 600;
-webkit-transition: color .3s ease-in-out,background-color .3s ease-in-out,opacity .3s ease-in-out;
transition: color .3s ease-in-out,background-color .3s ease-in-out,opacity .3s ease-in-out;
text-decoration: none;
letter-spacing: 1px;
padding: 1rem;
}

.dash_right {
  display: flex;
  margin-top: 5px;
}

.signup {
  background: #35373a !important;
  margin-right: 15px;
}

.button_zal {
  padding:11px 31px;
  text-align: center;
  text-transform: none;
  transition: 0.5s;
  color: #fff !important;
  border-radius: 5px;
  display: block;
  font-size: 15px;
  font-weight: 600;
  border: none;
  background: #7d5fd2;
  margin-top:-10px;
}

.button_zal a {
  color: #fff;
}

.login {
  background: #7d5fd2 !important;
  padding: 11px 22px !important;
}

.nav > li > a:focus, .nav > li > a:hover {
  text-decoration: none;
  background-color:transparent;
  color:#b323b3 !important;
}

.fixed-top {
  position: relative;
  // position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.navbar{
  margin-bottom: 0;
}

.search-div{
  padding:9px;
  width:93%;
}

 nav .search-div{
  padding: 6px;
}

.search-box {
  border: 1px solid #D3D3DC;
  width: 370px;
  background-color: #fff;
  border-radius: 9px;
  height: 46px;
  padding: 0 10px;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  color: #7B7794;
  font-weight: 600;
}

.search-div {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center;
}

.search-box::placeholder {
  color: #7B7794 !important;
  font-weight: 600;
}

.search-img {
  border-right: none;
  width:7%;
  height: 23px;
  margin-top:12px;
  text-align: center;
  position: relative;
  img{
    padding-top: 0;
  }
}

.search-img img {
  max-width: 18px;
  max-height: 18px;
}

.search-img::before{
  content: "";
  height: 18px;
  width: 1px;
  position: absolute;
  right: -8px;
  background-color: #1B182B;
  top: 1px;
  border-radius: 8px;
}
.search-div input{
  width: 100%;
  color: #fff !important;
  background-color: transparent;
  border: none;
  font-size: 12px;
  font-weight: 500;
  padding-left:10px;
  word-spacing: 3px;
}

 nav .search-img::before {
  background-color: transparent;
}

.search-div input:hover ,   .search-div input:focus {
  outline: none;
}

.wdith50 {
  width: 50%;
}

nav .search-box{
  background-color: initial;
  border: 1px solid #fff;
  border-radius: 5px;
  display: flex;
  height: 45px;
  padding: 3px 5px;
  position: absolute;
  width: 77%;
  left: 0;
  top: -5px;
  display: flex ;
  flex-direction: row !important;
  justify-content: center !important;
}
.header-btn{
  cursor: pointer;
  display: flex;
}

@media (min-width: 1200px)
{
 nav .container {
  width: 100%;
  padding-left: 3% !important;
  padding-right: 2% !important;
}
}
 
nav .container {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 100%;
}
.row.policy-page {
  color: #fff;
  font-size: 16px;
  padding-top: 7%;
}
.policy_overflow
{
max-height:2500px;
overflow:auto;
}
.suggestion_box{
  background-color:transparent;
  border-bottom: 1px solid #2C293C;
  padding: 9px;
  display: flex;
  cursor: pointer;
  text-align: left;
}
.suggestion_name
{

margin-right: 3%;
color: #fff;
h5{
  font-size: 16px;
}
}

.suggestions-main ul {
  list-style: none;
  scrollbar-width: thin;
  scrollbar-color: #fff #fff;
}

.suggestions ul{
  list-style: none;
  scrollbar-width: thin;
  scrollbar-color: #211e32 #211e32;
}

/* Style the scrollbar background */
::-webkit-scrollbar {
  background: transparent;
}

 /* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #211E32 #1b182b;  
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #211E32;
}

*::-webkit-scrollbar-thumb {
  background-color: #1b182b;
  border-radius: 20px;
  border: 3px solid #1b182b;
}



.suggestions {
  scroll-behavior: auto;
  list-style: none;
  position: absolute;
  width: 100%;
  top: 54px;
  background-color: #211e32;
  border-radius: 9px;
  padding: 10px;
  border: 1px solid #fff;
  left: 0;
}

.suggestions ul{
overflow-y: auto;
overflow-x: hidden;
max-height: 250px;
}

.searhbar-icons {
  display: flex;
}
.cross-icon {
  font-size: 16px !important;
  color: #fff;
  cursor: pointer;
  padding: 3px;
}
.search-city-name{
  color: #7B7794;
}
.login_btn{
  color: #FFFFFF;
  font-size: 15px;
  padding:0 32px 0 0;


}

.flex_profile {
  display: flex;
  float:right;
}

.profile_div{
  padding-left: 12%;
  padding-top: 3px;
}

.profile_div a{
  cursor: pointer;
}

.profile_div img{
width: 21px;
}

.search_bar_right{
  width:30%;
  position: relative;
}

.source_logo_left {
  width:70%;
  padding-top: 15px;
}

.logo_mobile {
  width: auto;
  height: 30px;
  margin-top: -3px;
  margin-right: 10px;
}
.material-symbols-outlined{
  color: #7d5fd2;
  cursor: pointer;
}

@media (max-width: 1400px)
{
  .search_bar_right{
    width: 50%;
  }

  .source_logo_left{
    width: 50%;
  }
}

@media (max-width: 950px)
{
  .source_logo_left {
    width: 45%;
  }

  .search_bar_right {
    width: 100%;
  }

}


@media (max-width: 1000px)
{
.source_logo_left{
  padding-top:0;
  }
  
.navbar-brand {
  width: auto;
}

.navbar {
  padding: 20px;
}


.login{
  padding: 7px 15px !important;
  font-size: 15px!important;
  font-weight: 700!important;
  text-transform: capitalize!important;
  margin-top: 8px!important;
}

.login_btn{
  padding: 14px 20px 0 0;
  font-weight: 700;
}

.header{
  padding-top: 12px !important;
}

#home h2{
  line-height: 35px!important;
  font-size: 26px!important;
  text-align: center!important;
}

#home h5{
  font-size: 15px!important;
  font-weight: 500!important;
  color: rgb(238, 236, 236)!important;
  text-align: center!important;
  margin-left: auto;
  margin-right: auto;
  max-width: 390px!important;
  margin-top: 10px!important;
  text-transform: none!important;
  margin-bottom: 1em
}

#home h3{
  font-size: 16px !important;
  font-weight: 500 !important;
  text-align: center;
  padding-left: 0;
  width: auto;
  margin: auto;
  margin-bottom: auto;
  margin-bottom: 10px;
}

#home h3 img
{
width: 25px;
margin-right: 4px;
}

.suggestion_name-main h3{ 
  text-align: left !important;
}
 
.main-search-bar-inner-one {
  width: 100% !important;
}

.search-img{
  border-right: 0 !important;
}

.search-box{
  box-shadow:none;
}

.main-search-bar .search-div input{
  font-size: 11px !important;
  font-weight:500 !important; 
  padding-left: 10px !important;
}

.icon2 img , .icon3 img, .icon1 img {
  width: 50px !important;
}

::-ms-input-placeholder { /* Edge 12-18 */
  color:#666 !important;
}

::placeholder {
  color:#666 !important;
}
}

.search_icons
{
  width: 100px;
  border-right: 1px solid #A992FF;
  padding-right: 30px;
  padding-top: 6px;
  height: 30px;
  display: block;
  cursor: pointer;
}

@media (max-width:991px)
{
  .venue-map{
    background-color: transparent !important;
    position: fixed;
    z-index: 999;
  }
}

@media (max-width:768px)
{
  .search-box{
   // width: 98% !important;

    //top: 55px !important;
   // left: 1% !important;
   left: -29px !important;
   top: -8px !important;

  }

  nav .search-box{
    background-color: #2c293c;
  }

  .search_bar_right{
    //position: static;
  }

  #home .container{
    padding-left: 0 !important;
  }
  
  .header{
    padding-bottom: 0em !important;
  }

  .navbar-brand{
    padding:0!important;
    margin-top:0px;
  }
  
  .navbar{
    padding:0;
  }

  #home h2{
    font-size: 24px !important;
  }

  .search_icons{
    display: block;
    padding-top:5px;
  }

  #home h2{
    text-align: center !important;
  }

  #home h3{
    width: 280px;
    //margin-top: 15px;
    margin-bottom: 0;
  }

  .suggestion_name-main h3 {
    width:auto;
  }

  .search_bar_right{
    //width: 30%;
  }
  
  .source_logo_left{
    //width: 70%;
  }

  .suggestions{
    width: 100%;
  }

  nav .search-box{ 
    //display: none !important;
  }

  .profile_div{
    display: block;
  }

  .logo_mobile{
    height: 34px;
    margin-top: 0x;
    margin-right: 0px;
  }


  .navigation .logo{
    height: auto;
    margin-left: 15px;
    margin-top: 5px;
    width: auto;
  }
}


@media (max-width:550px)
{
  .search_icons{
    padding-right: 10px;
  }

  .icon1{
    top: 21em;
    left: 0%;
  }

  .search-img{
    margin-top:12px;
  }

  .main-search-bar {
    padding-top: 37px;
  }
}

.mobile-search-static {
  display: none !important;
  transition: all .3s;
  transform: translateX(150%);
}

.mobile-search-animation {
  transform: translateX(18%);
  border: none !important;
  display: none !important;
}


// .mobile-search-animation {
//   // width: 100vw !important; 
// }

.close {
  font-size: 16px;
  color: white !important;
  font-weight: 600;
}


#desktop-box {
  display: block;
}

#box {
  display: hidden;
}

.search-icon_g {
  display: hidden !important; 
  opacity: 0 !important;
}



@media (max-width:1250px)
{
  .mobile-search-animation {
    display: flex !important;
  }
  #desktop-box {
    display: hidden !important;
    opacity: 0 !important;
  }
  .search-icon_g {
    display: block !important;
    opacity: 1 !important;
    z-index: 0 !important;
  }
  
  #box {
    display: blcok;
  }
}

.close {
  z-index: 0 !important;
  position: relative;
}

.search_icon_p {
  padding: 4px 20px;
}