.signup-main{
    
.right-login{
    width: 100%;
}
.name-main {
    display: flex;
    width: 100%;
}
.email-input-main {
    display: flex;
}


input.email-input.country-code{
    width: 150px;
    padding: 0;
}
.email-box.country-code{
    padding: 0; margin-right: 10px;
    width: 17%;
}
input.form-control {
    width: 150px !important;
    background: #f6f6f9 !important;
    border-radius: 40px !important;
    /* padding: 15px 30px !important; */
    height: 48px !important;
}
.privacy-policies-main {
    display: flex;
    width: 100% !important;
}
.policies-text {
    /* width: 101%; */
    padding-left: 10px;
    color: #666666;
    font-size: 16px;
}
.login-btn-box {
   
    padding: 23px 0px;

}
.border-box {
    border: 1px solid #E6E7EA;
    align-self: stretch;
}
.sign-up-box {
 
    padding-top: 25px;
    font-size: 16px;
}

}

.email-text {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    color: #000;
}


.react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    border-radius: 100px 0 100px 0;
}

.email-box.country-code input.form-control {
    border: none !important;     height: 55px !important;
    box-shadow: none !important;     width: 100% !important;
}

.email-box.country-code .flag-dropdown
{
    border: 0px solid #cacaca; border-radius: 30px 0px 0px 30px !important;
}

.react-tel-input .flag-dropdown.open {
    z-index: 2;
    background: #f5f5f5 !important;
    border-radius: 3px 0 0 0; border-radius: 30px 0px 0px 30px !important;
}

.react-tel-input .flag-dropdown.open .selected-flag, .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
    background-color: #f5f5f5 !important; border-radius: 30px 0px 0px 30px !important;
}
.input-field-error {
    color: red;
}


