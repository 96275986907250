*,
:after,
:before {
  box-sizing: border-box;
  border: 0 solid;
}

:after,
:before {
  --tw-content: '';
}

.ml-4 {
  margin-left: 1rem;
}
.mr-4 {
  margin-right: 1rem;
}
:host,
html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  will-change: transform !important;
}

body {
  margin: 0;
  line-height: inherit;
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  margin-top: 10px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  max-height: 300px;
  overflow-y: auto;

  /* WebKit browsers */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2); /* Adjust the color and transparency */
    border-radius: 10px;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}
.search-results-mobile {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  margin-top: 10px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  max-height: 300px;
  overflow-y: auto;
  width: 95dvw;

  /* WebKit browsers */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2); /* Adjust the color and transparency */
    border-radius: 10px;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

/* Define the parent class */
.group-hover-parent {
  position: relative;
  text-decoration: none;
  /* Other styles for the parent */
}

/* Define the child class */
.group-hover-child {
  opacity: 0;
  transition: opacity 0.3s;
  text-decoration: none;
  color: white !important;
  /* Other styles for the child */
}

/* Apply styles to the child when the parent is hovered */
.group-hover-parent:hover .group-hover-child {
  color: #fff !important;
  opacity: 1;
}

a:hover {
  color: #fff !important;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
pre,
samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button,
select {
  text-transform: none;
}

button,
input:where([type='button']),
input:where([type='reset']),
input:where([type='submit']) {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
}

fieldset,
legend {
  padding: 0;
}

menu,
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

[role='button'],
button {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: block;
  vertical-align: middle;
}

img,
video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root {
  --background: 0 0% 100%;
  --foreground: 222.2 84% 4.9%;
  --card: 0 0% 100%;
  --card-foreground: 222.2 84% 4.9%;
  --popover: 0 0% 100%;
  --popover-foreground: 222.2 84% 4.9%;
  --primary: 222.2 47.4% 11.2%;
  --primary-foreground: 210 40% 98%;
  --secondary: 210 40% 96.1%;
  --secondary-foreground: 222.2 47.4% 11.2%;
  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;
  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;
  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 210 40% 98%;
  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;
  --ring: 222.2 84% 4.9%;
  --radius: 0.5rem;
  --chart-1: 12 76% 61%;
  --chart-2: 173 58% 39%;
  --chart-3: 197 37% 24%;
  --chart-4: 43 74% 66%;
  --chart-5: 27 87% 67%;
}

* {
  border-color: hsl(var(--border));
}

body {
  background-color: hsl(var(--background));
  color: hsl(var(--foreground));
}

*,
:after,
:before {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container-tw {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
}

@media (min-width: 1400px) {
  .container-tw {
    max-width: 1400px;
  }
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-right-10 {
  right: -2.5rem;
}

.-right-8 {
  right: -2rem;
}

.-top-20 {
  top: -5rem;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-1 {
  left: 0.25rem;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: 0.25rem;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-10 {
  top: 2.5rem;
}

.top-\[60px\] {
  top: 60px;
}

.top-full {
  top: 100%;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-50 {
  z-index: 50;
}

.z-\[1\] {
  z-index: 1;
}

.z-\[5\] {
  z-index: 5;
}

.-mb-\[0\.7px\] {
  margin-bottom: -0.7px;
}

.-mt-10 {
  margin-top: -2.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3\.5 {
  margin-top: 0.875rem !important;
}
.mt-5 {
  margin-top: 1.3rem !important;
}

.mt-6 {
  margin-top: 1.5rem;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.hidden-tw {
  display: none;
}

.aspect-video {
  aspect-ratio: 16/9;
}

.h-1 {
  height: 0.25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-4 {
  height: 1rem;
}

.h-7 {
  height: 1.75rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[2px\] {
  height: 2px;
}

.h-full {
  height: 100%;
}

.min-h-\[100dvh\] {
  min-height: 100dvh;
}

.min-h-\[35dvh\] {
  min-height: 35dvh;
}

.min-h-\[50dvh\] {
  min-height: 50dvh;
}

.min-h-\[75dvh\] {
  min-height: 75dvh;
}

.min-h-fit {
  min-height: fit-content;
}

.min-h-screen {
  min-height: 100vh;
}

.w-1\/2 {
  width: 50%;
}

.w-10 {
  width: 2.5rem;
}

.w-4 {
  width: 1rem;
}

.w-7 {
  width: 1.75rem;
}

.w-72 {
  width: 18rem;
}

.w-9 {
  width: 2.25rem;
}

.w-\[90\%\] {
  width: 90%;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-\[100dvw\] {
  min-width: 100dvw;
}

.min-w-fit {
  min-width: fit-content;
}

.min-w-full {
  min-width: 100%;
}

.max-w-\[100dvw\] {
  max-width: 100dvw;
}

.grow {
  flex-grow: 1;
}

.border-collapse {
  border-collapse: collapse;
}

.-translate-x-\[15\%\] {
  --tw-translate-x: -15%;
}

.-translate-x-\[15\%\],
.translate-y-\[-18\%\] {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
    skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[-18\%\] {
  --tw-translate-y: -18%;
}

.rotate-\[-3deg\] {
  --tw-rotate: -3deg;
}

.rotate-\[-3deg\],
.rotate-\[4deg\] {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
    skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[4deg\] {
  --tw-rotate: 4deg;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
    skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  user-select: none;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-\[12px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(12px * var(--tw-space-x-reverse));
  margin-left: calc(12px * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-clip {
  overflow: clip;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-clip {
  overflow-x: clip;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-lg {
  border-radius: var(--radius);
}

.rounded-md {
  border-radius: calc(var(--radius) - 2px);
}

.rounded-sm {
  border-radius: calc(var(--radius) - 4px);
}

.border {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-t,
.border-t-\[1px\] {
  border-top-width: 1px;
}

.border-none {
  border-style: none;
}

.border-\[\#C2C2C2\] {
  --tw-border-opacity: 1;
  border-color: rgb(194 194 194 / var(--tw-border-opacity));
}

.border-gray {
  --tw-border-opacity: 1;
  border-color: rgb(51 51 51 / var(--tw-border-opacity));
}

.border-gray\/35 {
  border-color: rgba(51, 51, 51, 0.35);
}

.border-highlight-2 {
  --tw-border-opacity: 1;
  border-color: rgb(130 68 220 / var(--tw-border-opacity));
}

.border-input {
  border-color: hsl(var(--input));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-white\/10 {
  border-color: hsla(0, 0%, 100%, 0.1);
}

.border-white\/30 {
  border-color: hsla(0, 0%, 100%, 0.3);
}

.border-white\/60 {
  border-color: hsla(0, 0%, 100%, 0.6);
}

.border-t-\[\#E6E6E6\] {
  --tw-border-opacity: 1;
  border-top-color: rgb(230 230 230 / var(--tw-border-opacity));
}

.bg-\[\#1A0E30\] {
  --tw-bg-opacity: 1;
  background-color: rgb(26 14 48 / var(--tw-bg-opacity));
}

.bg-\[\#E0E0E0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(224 224 224 / var(--tw-bg-opacity));
}

.bg-\[\#F2F2F2\] {
  --tw-bg-opacity: 1;
  background-color: rgb(242 242 242 / var(--tw-bg-opacity));
}

.bg-\[\#F6F6F6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(246 246 246 / var(--tw-bg-opacity));
}

.bg-\[\#F9F9F9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249 / var(--tw-bg-opacity));
}

.bg-accent {
  background-color: hsl(var(--accent));
}

.bg-background {
  background-color: hsl(var(--background));
}

.bg-destructive {
  background-color: hsl(var(--destructive));
}

.bg-highlight {
  --tw-bg-opacity: 1;
  background-color: rgb(130 68 238 / var(--tw-bg-opacity));
}

.bg-highlight-2 {
  --tw-bg-opacity: 1;
  background-color: rgb(130 68 220 / var(--tw-bg-opacity));
}

.bg-inherit {
  background-color: inherit;
}

.bg-main {
  --tw-bg-opacity: 1;
  background-color: rgb(17 10 34 / var(--tw-bg-opacity));
}

.bg-popover {
  background-color: hsl(var(--popover));
}

.bg-primary {
  background-color: hsl(var(--primary));
}

.bg-secondary {
  background-color: hsl(var(--secondary));
}

.bg-transparent {
  background-color: transparent;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-\[\#F6F6F6\] {
  --tw-gradient-from: #f6f6f6 var(--tw-gradient-from-position);
  --tw-gradient-to: hsla(0, 0%, 96%, 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-\[\#F6F6F6\] {
  --tw-gradient-to: #f6f6f6 var(--tw-gradient-to-position);
}

.object-cover {
  object-fit: cover;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-\[60px\] {
  padding-top: 60px;
}

.pt-\[80px\] {
  padding-top: 80px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-\[0\.8rem\] {
  font-size: 0.8rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[24px\] {
  font-size: 24px;
}

.text-\[26px\] {
  font-size: 26px;
}

.text-\[28px\] {
  font-size: 28px;
}

.text-\[30px\] {
  font-size: 30px;
}

.text-\[32px\] {
  font-size: 32px;
}

.text-\[36px\] {
  font-size: 36px;
}

.text-\[48px\] {
  font-size: 48px;
}

.text-\[64px\] {
  font-size: 64px;
}

.text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.leading-loose {
  line-height: 2;
}

.leading-none {
  line-height: 1;
}

.leading-snug {
  line-height: 1.375;
}

.leading-tight {
  line-height: 1.25;
}

.text-\[\#E6E6E6\] {
  --tw-text-opacity: 1;
  color: rgb(230 230 230 / var(--tw-text-opacity));
}

.text-\[\#F1DEDE99\] {
  color: #f1dede99;
}

.text-\[\#F2F2F2\] {
  --tw-text-opacity: 1;
  color: rgb(242 242 242 / var(--tw-text-opacity));
}

.text-\[\#F3F3F3\] {
  --tw-text-opacity: 1;
  color: rgb(243 243 243 / var(--tw-text-opacity));
}

.text-\[\#F6F6F6AB\] {
  color: #f6f6f6ab;
}

.text-accent-foreground {
  color: hsl(var(--accent-foreground));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-destructive-foreground {
  color: hsl(var(--destructive-foreground));
}

.text-gray {
  --tw-text-opacity: 1;
  color: #333333 !important;
}

.text-gray\/60 {
  color: rgba(51, 51, 51, 0.6);
}

.text-highlight-2 {
  --tw-text-opacity: 1;
  color: rgb(130 68 220 / var(--tw-text-opacity)) !important;
}

.text-inherit {
  color: inherit;
}

.text-muted-foreground {
  color: hsl(var(--muted-foreground));
}

.text-popover-foreground {
  color: hsl(var(--popover-foreground));
}

.text-primary {
  color: hsl(var(--primary));
}

.text-primary-foreground {
  color: hsl(var(--primary-foreground));
}

.text-secondary-foreground {
  color: hsl(var(--secondary-foreground));
}

.text-suppressed {
  --tw-text-opacity: 1;
  color: rgb(241 222 222 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-white\/30 {
  color: hsla(0, 0%, 100%, 0.3);
}

.text-white\/5 {
  color: hsla(0, 0%, 100%, 0.05);
}

.text-white\/50 {
  color: hsla(0, 0%, 100%, 0.5);
}

.text-white\/60 {
  color: hsla(0, 0%, 100%, 0.6);
}

.underline-offset-4 {
  text-underline-offset: 4px;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: 0.5;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
}

.shadow-lg,
.shadow-md {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.outline {
  outline-style: solid;
}

.ring-offset-background {
  --tw-ring-offset-color: hsl(var(--background));
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate)
    var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
    transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.duration-300 {
  transition-duration: 0.3s;
}

.duration-500 {
  transition-duration: 0.5s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes enter {
  0% {
    opacity: var(--tw-enter-opacity, 1);
    transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0)
      scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1))
      rotate(var(--tw-enter-rotate, 0));
  }
}

@keyframes exit {
  to {
    opacity: var(--tw-exit-opacity, 1);
    transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0)
      scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1))
      rotate(var(--tw-exit-rotate, 0));
  }
}

.duration-300 {
  animation-duration: 0.3s;
}

.duration-500 {
  animation-duration: 0.5s;
}

.ease-in-out {
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

/* .hero-blur {
  position: absolute;
  width: 414px;
  height: 414px;
  left: 80%;
  top: 30%;
  background: #8244dc;
  filter: blur(450px);
  border-radius: 500px;
} */

@font-face {
  font-family: 'Mona Sans';
  src: url('../font/Mona-Sans.woff2') format('woff2 supports variations'),
    url('../font/Mona-Sans.woff2') format('woff2-variations');
  font-weight: 200 900;
  font-stretch: 75% 125%;
}

html {
  width: 100dvw;
  max-width: 100dvw;
  overflow-x: hidden;
  font-size: 16px;
}

.mona-sans,
html {
  font-family: Mona Sans;
}

.hero-section {
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
  background-color: #110a22;
}

/* .about-us-blur {
  filter: blur(450px);
} */

.features-clip {
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
}

.features-blur {
  left: 80%;
  top: 40%;
  background: hsla(0, 0%, 100%, 0.15);
  border-radius: 100%;
}

/* .events-blur,
.features-blur {
  position: absolute;
  width: 414px;
  height: 414px;
  filter: blur(450px);
  will-change: filter !important;
} */

.events-blur {
  background: rgba(130, 68, 220, 0.3);
  border-radius: 500px;
}

.marquee {
  position: absolute;
  overflow: hidden;
  animation: marquee 5s linear infinite;
}

.marquee span {
  width: 50%;
  white-space: nowrap;
}

@keyframes marquee {
  0% {
    left: 0;
  }

  to {
    left: -100%;
  }
}

.logo-fade {
  -webkit-mask: linear-gradient(90deg, transparent, #fff 5%, #fff 95%, transparent);
  mask: linear-gradient(90deg, transparent, #fff 5%, #fff 95%, transparent);
}

/* .about-us-blur {
  left: 0;
  top: 0;
  filter: blur(450);
} */

/* .about-us-2-blur,
.about-us-blur {
  position: absolute;
  width: 414px;
  height: 414px;
  background: rgba(51, 51, 51, 0.4);
  border-radius: 500px;
  will-change: filter !important;
} */

/* .about-us-2-blur {
  right: 0;
  bottom: 0;
  filter: blur(450px);
} */

.search-hero {
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
}

@media (min-width: 1024px) {
  .lg\:container-tw {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @media (min-width: 1400px) {
    .lg\:container-tw {
      max-width: 1400px;
    }
  }
}

.\*\:whitespace-nowrap > * {
  white-space: nowrap;
}

.focus-within\:relative:focus-within {
  position: relative;
}

.focus-within\:z-20:focus-within {
  z-index: 20;
}

.hover\:border-highlight-2:hover {
  --tw-border-opacity: 1;
  border-color: rgb(130 68 220 / var(--tw-border-opacity));
}

.hover\:bg-\[\#161616\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(22 22 22 / var(--tw-bg-opacity));
}

.hover\:bg-accent:hover {
  background-color: hsl(var(--accent));
}

.hover\:bg-destructive\/90:hover {
  background-color: hsl(var(--destructive) / 0.9);
}

.hover\:bg-highlight-2\/50:hover {
  background-color: rgba(130, 68, 220, 0.5);
}

.hover\:bg-highlight-2\/10:hover {
  background-color: rgba(130, 68, 220, 0.1);
}

.hover\:bg-primary:hover {
  background-color: hsl(var(--primary));
}

.hover\:bg-primary\/90:hover {
  background-color: hsl(var(--primary) / 0.9);
}

.hover\:bg-secondary\/80:hover {
  background-color: hsl(var(--secondary) / 0.8);
}

.hover\:text-accent-foreground:hover {
  color: hsl(var(--accent-foreground));
}

.hover\:text-highlight-2:hover {
  --tw-text-opacity: 1;
  color: rgb(130 68 220 / var(--tw-text-opacity));
}

.hover\:text-primary-foreground:hover {
  color: hsl(var(--primary-foreground));
}

.hover\:text-white\/80:hover {
  color: hsla(0, 0%, 100%, 0.8);
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:opacity-100:hover {
  opacity: 1 !important;
}

.focus\:bg-primary:focus {
  background-color: hsl(var(--primary));
}

.focus\:text-primary-foreground:focus {
  color: hsl(var(--primary-foreground));
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus-visible\:outline-none:focus-visible {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus-visible\:ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-ring:focus-visible {
  --tw-ring-color: hsl(var(--ring));
}

.focus-visible\:ring-offset-2:focus-visible {
  --tw-ring-offset-width: 2px;
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}

.disabled\:text-white\/50:disabled {
  color: hsla(0, 0%, 100%, 0.5);
}

.disabled\:opacity-50:disabled {
  opacity: 0.5 !important;
}

.group:hover .group-hover\:right-28 {
  right: 7rem;
}

.group:hover .group-hover\:translate-x-5 {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
    skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:to-transparent {
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.group:hover .group-hover\:font-bold {
  font-weight: 700;
}

.group:hover .group-hover\:text-highlight-2 {
  --tw-text-opacity: 1;
  color: rgb(130 68 220 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-white\/60 {
  color: hsla(0, 0%, 100%, 0.6);
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:opacity-50 {
  opacity: 0.5 !important;
}
.group:hover .group-hover\:opacity-20 {
  opacity: 0.2 !important;
}

.group:hover .group-hover\:blur-sm {
  --tw-blur: blur(4px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate)
    var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.aria-selected\:bg-accent[aria-selected='true'] {
  background-color: hsl(var(--accent));
}

.event-item-title {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 80dvw !important;
  min-height: 120% !important;
  overflow: hidden !important;
  @media (min-width: 1024px) {
    height: 53px !important;
    max-width: 30ch !important;
  }
}

.event-item-title-bg {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 70dvw !important;
  min-height: 120% !important;
  overflow: hidden !important;
  text-align: center !important;
  @media (min-width: 1024px) {
    height: 53px !important;
    max-width: 50ch !important;
  }
}

.aria-selected\:bg-accent\/50[aria-selected='true'] {
  background-color: hsl(var(--accent) / 0.5);
}

.aria-selected\:text-accent-foreground[aria-selected='true'] {
  color: hsl(var(--accent-foreground));
}

.aria-selected\:text-muted-foreground[aria-selected='true'] {
  color: hsl(var(--muted-foreground));
}

.aria-selected\:opacity-100[aria-selected='true'] {
  opacity: 1;
}

.data-\[state\=open\]\:animate-in[data-state='open'] {
  animation-name: enter;
  animation-duration: 0.15s;
  --tw-enter-opacity: initial;
  --tw-enter-scale: initial;
  --tw-enter-rotate: initial;
  --tw-enter-translate-x: initial;
  --tw-enter-translate-y: initial;
}

.data-\[state\=closed\]\:animate-out[data-state='closed'] {
  animation-name: exit;
  animation-duration: 0.15s;
  --tw-exit-opacity: initial;
  --tw-exit-scale: initial;
  --tw-exit-rotate: initial;
  --tw-exit-translate-x: initial;
  --tw-exit-translate-y: initial;
}

.data-\[state\=closed\]\:fade-out-0[data-state='closed'] {
  --tw-exit-opacity: 0;
}

.data-\[state\=open\]\:fade-in-0[data-state='open'] {
  --tw-enter-opacity: 0;
}

.data-\[state\=closed\]\:zoom-out-95[data-state='closed'] {
  --tw-exit-scale: 0.95;
}

.data-\[state\=open\]\:zoom-in-95[data-state='open'] {
  --tw-enter-scale: 0.95;
}

.data-\[side\=bottom\]\:slide-in-from-top-2[data-side='bottom'] {
  --tw-enter-translate-y: -0.5rem;
}

.data-\[side\=left\]\:slide-in-from-right-2[data-side='left'] {
  --tw-enter-translate-x: 0.5rem;
}

.data-\[side\=right\]\:slide-in-from-left-2[data-side='right'] {
  --tw-enter-translate-x: -0.5rem;
}

.data-\[side\=top\]\:slide-in-from-bottom-2[data-side='top'] {
  --tw-enter-translate-y: 0.5rem;
}

@media (min-width: 640px) {
  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
}

@media (min-width: 768px) {
  .md\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:absolute {
    position: absolute !important;
  }

  .lg\:-top-24 {
    top: -4.5rem !important;
  }

  .lg\:top-10 {
    top: 2.5rem !important;
  }

  .lg\:mt-0 {
    margin-top: 0 !important;
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .lg\:block {
    display: block !important;
  }

  .lg\:flex {
    display: flex !important;
  }

  .lg\:hidden-tw {
    display: none !important;
  }

  .lg\:min-h-\[25dvh\] {
    min-height: 25dvh !important;
  }

  .lg\:min-h-\[45dvh\] {
    min-height: 45dvh !important;
  }

  .lg\:w-1\/12 {
    width: 8.333333% !important;
  }

  .lg\:w-1\/6 {
    width: 16.666667% !important;
  }

  .lg\:w-\[60\%\] {
    width: 60% !important;
  }

  .lg\:w-\[calc\(33\.33\%-1rem\)\] {
    width: calc(33.33% - 1rem) !important;
  }

  .lg\:w-auto {
    width: auto !important;
  }

  .lg\:w-fit {
    width: fit-content !important;
  }

  .lg\:w-full {
    width: 100% !important;
  }

  .lg\:min-w-\[555px\] {
    min-width: 555px !important;
  }

  .lg\:min-w-fit {
    min-width: fit-content !important;
  }

  .lg\:max-w-\[45dvw\] {
    max-width: 45dvw !important;
  }

  .lg\:max-w-\[55\%\] {
    max-width: 55% !important;
  }

  .lg\:max-w-\[65\%\] {
    max-width: 65% !important;
  }

  .lg\:max-w-\[70\%\] {
    max-width: 70% !important;
  }

  .lg\:max-w-\[900px\] {
    max-width: 900px !important;
  }

  .lg\:translate-x-0 {
    --tw-translate-x: 0px !important;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
      skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:flex-row {
    flex-direction: row !important;
  }

  .lg\:flex-col {
    flex-direction: column !important;
  }

  .lg\:items-center {
    align-items: center !important;
  }

  .lg\:justify-normal {
    justify-content: normal !important;
  }

  .lg\:gap-0 {
    gap: 0 !important;
  }

  .lg\:gap-10 {
    gap: 2.5rem !important;
  }

  .lg\:gap-36 {
    gap: 9rem !important;
  }

  .lg\:gap-4 {
    gap: 1rem !important;
  }

  .lg\:border-b {
    border-bottom-width: 1px !important;
  }
  .lg\:border-b-0 {
    border-bottom-width: 0px !important;
  }

  .lg\:border-r {
    border-right-width: 1px !important;
  }

  .lg\:p-24 {
    padding: 6rem !important;
  }

  .lg\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .lg\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .lg\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .lg\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .lg\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .lg\:pb-10 {
    padding-bottom: 2.5rem !important;
  }

  .lg\:pb-24 {
    padding-bottom: 6rem !important;
  }

  .lg\:pb-4 {
    padding-bottom: 1rem !important;
  }

  .lg\:text-left {
    text-align: left !important;
  }

  .lg\:text-\[16px\] {
    font-size: 16px !important;
  }

  .lg\:text-\[20px\] {
    font-size: 20px !important;
  }

  .lg\:text-\[24px\] {
    font-size: 24px !important;
  }

  .lg\:text-\[40px\] {
    font-size: 40px !important;
  }

  .lg\:text-\[48px\] {
    font-size: 48px !important;
  }

  .lg\:text-\[56px\] {
    font-size: 56px !important;
  }

  .lg\:text-\[64px\] {
    font-size: 64px !important;
  }

  .lg\:text-base {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  .lg\:leading-normal {
    line-height: 1.5 !important;
  }

  .lg\:opacity-0 {
    opacity: 0 !important;
  }

  .lg\:hover\:text-white\/60:hover {
    color: hsla(0, 0%, 100%, 0.6);
  }
}

@media (min-width: 1280px) {
  .xl\:-top-10 {
    top: -2.5rem;
  }

  .lg\:-top-24 {
    top: -6rem;
  }

  .xl\:max-w-\[1440px\] {
    max-width: 1440px;
  }

  .xl\:gap-10 {
    gap: 2.5rem;
  }
}

.\[\&\:has\(\[aria-selected\]\)\]\:bg-accent:has([aria-selected]) {
  background-color: hsl(var(--accent));
}

.first\:\[\&\:has\(\[aria-selected\]\)\]\:rounded-l-md:has([aria-selected]):first-child {
  border-top-left-radius: calc(var(--radius) - 2px);
  border-bottom-left-radius: calc(var(--radius) - 2px);
}

.last\:\[\&\:has\(\[aria-selected\]\)\]\:rounded-r-md:has([aria-selected]):last-child {
  border-top-right-radius: calc(var(--radius) - 2px);
  border-bottom-right-radius: calc(var(--radius) - 2px);
}

.\[\&\:has\(\[aria-selected\]\.day-outside\)\]\:bg-accent\/50:has([aria-selected].day-outside) {
  background-color: hsl(var(--accent) / 0.5);
}

.\[\&\:has\(\[aria-selected\]\.day-range-end\)\]\:rounded-r-md:has([aria-selected].day-range-end) {
  border-top-right-radius: calc(var(--radius) - 2px);
  border-bottom-right-radius: calc(var(--radius) - 2px);
}

.\[\&_svg\]\:pointer-events-none svg {
  pointer-events: none;
}

.\[\&_svg\]\:size-4 svg {
  width: 1rem;
  height: 1rem;
}

.\[\&_svg\]\:shrink-0 svg {
  flex-shrink: 0;
}

.hero-image {
  margin-top: -50px;
  @media (min-width: 1024px) {
    position: absolute;
    top: 0%;
    right: -5%;
    width: 50%;
    height: 100%;
  }
}

.hero-image img {
  transform: scale(0.9);
}

button:disabled{
  background-color: #8244dc80;
}