.header {
  // background-image: url(https://sourcedtickets.com/static/media/hero.e98663bf8fc64145807c.png);
  background-image: url(../../images/headerv2.png);
  // background-size: 100% auto;
  background-size: cover;
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 100%;
  background-color: transparent;
}

#home .container {
  padding-left: 0;
  padding-right: 0;
  padding-top: 6%;
  position: relative;
  z-index: 999;
  padding-bottom: 20rem;
  max-width: 1500px;
}
#home h1 {
  font-size: 26px;
  text-transform: none;
  margin-top: 0;
  line-height: 42px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 11px;
}

#home h5 {
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  text-align: left;
  margin-top: 1em;
  margin-bottom: 2em;
  text-transform: capitalize;
  text-shadow: 0px 4px 33px rgba(0, 0, 0, 0.25);
  max-width: 436px;
  line-height: 22px;
}

#home h3 {
  color: #ffffffb8;
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
}
.main-col {
  text-align: center;
}
.purchase_btn {
  max-width: 100%;
  margin: auto;
  margin-top: auto;
  margin-top: auto;
  cursor: pointer;
}

.purchase_btn .button_zal {
  background: #7d5fd2;
  border: none;
  margin-right: 0%;
  padding: 7px 10px 9px 10px;
  text-transform: capitalize;
  width: 164px;
  text-align: left;
  margin-top: 16px;
}

.purchase_btn .button_zal .fa-arrow-right {
  float: right;

  font-size: 16px;
}

.button_zal {
  padding: 15px 23px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  color: #fff !important;
  border-radius: 6px;
  display: block;
  font-size: 15px;
  font-weight: 600;
  border: none;
  background: #fff;
}

.hero-right {
  text-align: right;
  padding-right: 2%;
}

/* slider css*/
.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
/* slider css*/

.search-div input:focus {
  outline: none !important;
}

.icons {
  position: absolute;
  left: 0;
  top: 0;
}

.icon1 {
  position: absolute;
  left: 62%;
  top: 9em;
}

.icon1 img {
  width: 65px;
}

.icon2 {
  position: absolute;
  top: 10em;
  left: -10em;
}

.icon2 img {
  width: 65px;
}

.icon3 {
  position: absolute;
  left: 53%;
  top: 37em;
}

.icon3 img {
  width: 65px;
}
.main-search-bar {
  //display: flex;
  padding-top: 30px;
  .search-div input {
    width: 100%;
    color: #7d5fd2 !important;
    background-color: transparent;
    border: none;
    font-size: 15px;
    font-weight: 600;
    padding-left: 10px;
    word-spacing: 3px;
  }
}
.main-search-bar-inner-one {
  position: relative;
  display: flex;
  justify-content: center;
}
.main-intro {
  display: flex;
  flex-wrap: wrap;
  input::placeholder {
    color: #7b7794;
  }
}

.suggestions-main {
  background: #fff;
  border-radius: 9px;
  top: 55px;
  position: absolute;
  padding: 10px !important;
  list-style: none;
  width: 370px;
  transition: all cubic-bezier(0.86, 0, 0.07, 1) 1s;
  z-index: 9;
}

/* Style the scrollbar background */
::-webkit-scrollbar {
  background: transparent;
}

/* Style the scrollbar handle */
.suggestions-main ul::-webkit-scrollbar-thumb {
  background: #fff;
}

.suggestions ul {
  /* Style the scrollbar handle */
  ::-webkit-scrollbar-thumb {
    background: #fff;
  }
}

/* Style the scrollbar handle */
::-webkit-scrollbar-thumb {
  background: #211e32;
}

.suggestions-main ul {
  /* Style the scrollbar handle */
  ::-webkit-scrollbar-thumb {
    background: #fff;
  }
}

.suggestions-main ul {
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
}

.suggestions ul {
  margin: 0;
  padding: 0;
}

.suggestions-main ul li {
  list-style-type: none;
}

.suggestion_name-main h4 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
}
.suggestion_name-main h3 {
  color: #7b7794 !important;
  margin-bottom: 0;
  font-weight: 500 !important;
  margin-top: 0;
  font-size: 14px !important;
  text-align: center ;
}

.suggestion_name-main h3:hover {
  color: #8244e6 !important;
  cursor: pointer;
}

.suggest_h2 {
  font-size: 19px !important;
}

.suggest_h3 {
  font-size: 15px !important;
}

.suggestion_name-main {
  padding: 5px;
}

.suggestion_name-main {
  padding: 5px;
}

li.suggestion-list-items-main {
  border-top: 1px solid #d9d9d9;
  padding: 5px 0 5px 0;
  cursor: pointer;
}
.suggestions-main::-webkit-scrollbar {
  width: 2px !important;
}
.cross-main {
  font-size: 16px !important;
  padding: 5;
  cursor: pointer;
}

@media (max-width: 2050px) {
  .header {
    background-size: 100% 100%;
  }
}

@media (max-width: 1600px) {
  #home .container {
    max-width: 1202px;
  }
}

@media (max-width: 1300px) {
  #home .container {
    max-width: 1100px;
    padding-left: 5%;
  }

  .icon2 {
    top: 5em;
    left: -3em;
  }

  .icon2 img {
    width: 55px;
  }
}

@media (max-width: 1200px) {
  .icon2 {
    top: 4em;
    left: -1em;
  }
}

@media (max-width: 1000px) {
  #home .container {
    padding-left: 10%;
  }
}

@media (max-width: 990px) {
  #home .container {
    width: 90%;
  }

  .icon2 {
    top: 4em;
    left: 2em;
  }
  #home .container {
    padding-bottom: 10rem;
  }
}

@media (max-width: 768px) {
  .suggestions-main {
    width: 100%;
  }

  .profile_div {
    padding-top: 3px;
  }

  .icons {
    position: absolute;
    width: 100%;
  }

  #home .container {
    padding-top: 7%;
  }

  .icon2 {
    left: 0;
    top: -30em;
    right: auto;
  }

  .icon3 {
    right: 0%;
    top: 4em;
    left: auto;
  }

  .icon1 {
    left: 0%;
    top: 21em;
  }
}

@media (max-width: 768px) {
  #home h3 {
    // color: rgba(255, 255, 255, 0.70) !important;
    font-size: 13px !important;
    font-weight: 500;
    text-transform: capitalize;
  }

  .icon2 {
    left: 3%;
    top: 5em;
  }

  .main-search-bar {
    padding-left: 5%;
    padding-right: 5%;
  }
  #home .container {
    padding-bottom: 10rem;
  }

  .header {
    background-image: url('../../images/headerv2.png');
    h1 {
      color: #fff;
    }
  }
}

@media (max-width: 428px) {
  .icon2 img,
  .icon3 img,
  .icon1 img {
    width: 40px !important;
  }
}

// .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, .login_btn , a, p, button, input,
//  .purchase_btn .button_zal, .filter-apply, .filter-clear {
//   font-family: Poppins !important;
// }
