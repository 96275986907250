.calendar {
  width: 300px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  margin-top: 10px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e2e2e2;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  color: #000;
}

.calendar-header button {
  background: none;
  border: none;
  font-size: 18px;
  color: #000;
  cursor: pointer;
  transition: transform 0.2s;
}

.calendar-header button:hover {
  transform: scale(1.2);
}

.calendar-header h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.calendar-weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  background-color: #f8f8f8;
}

.calendar-weekdays div {
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  color: #757575;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar-day {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  font-size: 14px;
  border-radius: 100%;
}

.calendar-day:hover {
  background-color: #f0f0f0;
  border-radius: 100%;
}

.calendar-day.empty {
  background-color: #ffffff;
  cursor: default;
}

.calendar-day.current {
  background-color: #e8f5e9;
  color: #4caf50;
  font-weight: bold;
}

.calendar-day.selected {
  background-color: #8244dc;
  color: #ffffff;
  font-weight: bold;
}

.calendar-day.selected:hover {
  background-color: #8244dc;
}

.calendar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #d9d9d99a;
  color: #000;
  padding: 10px;
  border-radius: 10px;
  transition: background-color 0.2s;
}

.calendar-button h1 {
  font-size: 18px;
}

.calendar-button:hover {
  background-color: #d9d9d977;
}

.calendar-container {
  position: relative;
}

.calendar-day.disabled {
  color: #ccc;
  pointer-events: none; /* Prevent clicking */
  opacity: 0.5;
}
