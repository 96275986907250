#sell {
    background-color: #1b182b;
    padding-top: 110px;
    padding-bottom: 140px;
  }
 
  .sell_box {
    background-image:url("../../images/newimages/sellnow.png");
    background-size: 100% 100%;
    background-position: 0 0;
    background-repeat: no-repeat;
    width: 100%;
    border-radius: 6px;
    padding: 50px 14px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 95%;
    margin: 0 auto;
  }

  #sell h5 {
    color: #fff;
    margin-bottom: 0;
    font-size: 29px;
    font-weight: 600;
    margin-top: 0;
    line-height: 40px;
  }
 
  #sell p {
    color: #fff;
    margin-top: 20px;
    font-size: 19px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;
  }

  #sell button{
    background-color: #fff;
    background-image: url("../../images/newimages/sellnow.png");
    background-size: 30px auto;
    background-position: 90% center;
    background-repeat: no-repeat;
    width: 225px;
    border-radius: 7px;
    color: #1B182B;
    font-size: 15px;
    text-transform: capitalize;
    font-weight: 600;
    padding: 10px 18px;
    text-align: left;
    border: 1px solid #fff;
  }

  #sell button a{
  color: #1B182B;
  text-decoration: none;
  font-weight: 700;
  }
  .hiden_quantity{
    .purchase_btn{
      justify-content: center;
      .button_zal {
        color: #000 !important;
        background-color: #fff;
        .fa-arrow-right{
          padding: 3px;
        }
         
        
      }
    }

  }

  @media (max-width:768px)
  {
    #sell h5{
      line-height: 25px;
      font-size: 21px;
    }

    #sell h5 strong{
  font-size: 30px;
  position: relative;
  top: 20px;  
    }

    #sell p{
      font-weight: 600;
      margin-top: 40px;
    }

    .sell_box {
      background-image:url("../../newimages/sell-mobile.png");
    }

  }



  .footer {
    padding-left: 1em;
    padding-right: 1em;
  }