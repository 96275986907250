.location-input {
  position: relative;
  width: 250px;
}

.location-display {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  background-color: #d9d9d99a;
  color: #000;
  padding: 10px;
  border-radius: 10px;
  transition: background-color 0.2s;
}

.location-display h1 {
  font-size: 18px;
}

.location-display:hover {
  background-color: #d9d9d977;
}

.location-popup {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  padding: 2px;
}

.location-search-input {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #eee;
  font-size: 14px;
}

.location-search-input:focus {
  outline: none;
}

.location-search-input::placeholder {
  color: #999;
}

.location-actions {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  gap: 10px;
}

.apply-button,
.clear-button {
  padding: 6px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  transition: background-color 0.3s ease;
}

.apply-button {
  background-color: rgb(130 68 220);
  color: #fff;
}

.apply-button:hover {
  background-color: rgba(129, 68, 220, 0.9);
}

.clear-button {
  background-color: #f0f0f0;
  color: #333;
}

.clear-button:hover {
  background-color: #e0e0e0;
}

.autocomplete-dropdown{
  padding: 20px;
}

.autocompete-item{
  padding: 10px;
  cursor: pointer;
}

.autocomplete-header{
  padding: 10px;
  display: flex;
  justify-content:flex-start;
  align-items: center;
  cursor: pointer;
}