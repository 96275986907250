#footer {
  width: 100%;
  background-image: none;
  background-size: 100% auto;
  background-position: 0 center;
  background-color: #211E32;
  position: relative;
  z-index: 0;  
  }

  #footer .footer-top {
    padding:60px 0 60px 0;
    text-align: center;
    position: relative;
  }

  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
    text-align: left;
  }

  .Zaltol_footer {
    width: auto;
  margin-bottom: 3em;
  margin-top: 3em;
  }
   
  #footer a {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
  }

  #footer ul {
    padding: 0;
    display: block;
    margin: auto;
      margin-top: auto;
    margin-top: 0;
    text-align: left;
  }


  #footer li {
    list-style: none;
    margin-right: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  #footer li {
    color: #7B7794;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;

  }
  #footer a {
    color: #7B7794;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;

  }


  #footer h3{
    text-align: left;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    margin-top: 2.7em;
    margin-bottom: 2em;
  }

  #footer p{
    color: #7B7794;
font-size: 14px;
font-weight: 600;
line-height: 21px; 
  }
  a.open-policies {
    cursor: pointer;
}



@media (max-width:768px)
{
#footer .footer-top
{
  padding: 0px 0 30px 0 !important;
}



.Zaltol_footer{
  margin-bottom: 1em;
}

#footer h3{
  margin-top: 0;
  margin-bottom: 0em;
}

#footer a{
  font-size: 14px;
}
}

@media (max-width:575px)
{
  .col-xs-6 {
    width: 50% !important;
  }

  .col-xs-4{
    width: 33.33333333% !important;
  }
  
}

