#artists  .date h3
{
color: #a992ff;
}

#artists  .date {
  width: 8%;
  padding-left: 7px;
  margin-right: 5%;
}

#artists h3{
  color: #fff;
  font-size: 23px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
}

#artists  .Info {
  width: 87%;
}
.date h4 {
  color: #fff !important;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0;
}
.date h3 {
  color: #fff !important;
  font-size: 14px !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;

}

.top-events {
  h4{
    color: #fff;
  }

}

