/* Sidebar.css */
.sidebar {
    position: fixed;
    right: -250px;
    top: 0;
    height: 100%;
    width: 250px;
    background-color: #1b182b;
    color: white;
    transition: right 0.3s ease;
    padding-top: 60px;
    z-index: 10 !important;
  }
  
  .sidebar.open {
    right: 0;
  }
  
  .sidebar .close-btn {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    border: none;
    background: none;
    color: white;
    cursor: pointer;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
    text-align:left
    
  }
  
  .sidebar  ul li {
    padding: 8px 16px;
    font-size: 18px;
    border-bottom: 0.5px dotted;
    cursor: pointer;
  
  }
  
  .sidebar  ul :hover{
    
        background-color: #A992FF;
    
  }
  .sidebar  ul li a {
    color: white;
    text-decoration: none;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  