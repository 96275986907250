#feat{
    border: 1px solid #2C293C;
    background: #211E32;
    padding: 80px 0px;
    padding-top: 50px;
    padding-left: 1em;
    padding-right: 1em;
}

#feat .section-heading{
    padding: 12px;
    color: #fff;
    font-size: 27px;
    font-weight: 700;
    text-align: center;
    margin-top: 0;
    margin-bottom: 60px !important;
}

#feat .section-heading span{
    color:#A992FF;
}

.feat_box {
    border-radius: 10px;
    border: 1px solid #3A3749;
    background: #29263A;
    padding: 20px 40px;
    min-height: 223px;
    margin-bottom: 2em;
}

.feat_box h4{
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 35px;
    margin-top: 0;
    text-align: center;
    padding-right: 5px;
}

.feat_box p{
    color: #7B7794;
font-size: 14px;
font-weight: 600;
text-align: center;
}

@media (min-width: 1200px)
{
 #feat .container {
    max-width: 100%;
    width: 1350px;
}
}

@media (max-width:768px)
{
#feat .section-heading
{
    font-size:25px;
    margin-bottom: 25px !important;
}

#feat{
    padding-top: 20px;
}

.feat_box h4{
    text-align: center;
    margin-bottom: 20px;
}

.feat_box{
    min-height: auto;
    margin-bottom: 1.4em;
}
}