@import '../src/assets/styles/header/header';
@import '../src/assets/styles/footer/footer';
@import '../src/assets/styles/home/home';
@import '../src/assets/styles/search-bar/search-bar';
@import '../src/assets/styles/main-intro/main-intro';
@import '../src/assets/styles/unmatched-feature/unmatched-feature';
@import '../src/assets/styles/local-events/local-events';
@import '../src/assets/styles/search-results/search-results';
@import '../src/assets/styles/login/login';
@import '../src/assets/styles/signup/signup';
@import '../src/assets/styles/forgot-password/forgotPassword';
@import '../src/assets/styles/event-details/eventdetails';

@import '../src/assets/styles/performer-search/performer-search';
@import '../src/assets/styles/top-section/top-section.scss';
@import '../src/assets/styles/sell-now/sell-now';
@import '../src/assets/styles/sports/sports';
@import '../src/assets/styles/sidebar/sidebar';

body {
  max-width: 100vw;
  overflow-x: hidden;
}

html,
body {
  position: relative;
  overflow-x: hidden;
}
