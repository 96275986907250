
 
.forgot-password-main-container {
    /* padding: 50px 50px 50px 71px; */
    padding: 5% 28% 5% 29%;
  }
  .login-internal {
    width: 100%;
    display: flex;
    /* padding: 20px; */
  }
  .left-login {
    width: 50%;
  
    background: linear-gradient(
        0deg,
        rgba(4, 102, 255, 0.1),
        rgba(4, 102, 255, 0.1)
      ),
      url("../../images/splash_bg.png");
  }
  .email-text {
    text-align: left;
}
  .right-login {
    width: 50%;
  }
  .right-login-box {
    padding-top: 50px;
  }
  .login-logo {
    text-align: center;
  }
  img.login-logo-img {
    height: 60px;
    width: 318px;
}
  .left-login-img {
    text-align: center;
  }
  .login-user {
    text-align: center;
    font-size: 24px; margin-top: 20px;
  }
  .login-form-container {
    padding: 37px 30px 50px 30px;
  }
  .login-form-internal {
    padding: 0px 0px 0 0px;
    width: 680px;
    margin: auto;
  }
  input.email-input {
    background: #f6f6f9;
    border-radius: 45px;
    border: none;     height: 55px;
    width: 100%;
    font-size: 16px;
    color: #8B939A;
    padding: 15px 30px;
  }
  .email-box {
    padding-top: 15px;
  }
  .password {
    padding-top: 15px;
  }
  .remember-me-box {
    padding-top: 27px;
  }
  .forgot-password {
    font-size: 16px;
    color: #0466ff;
    cursor: pointer;
  }
  .login-btn-box {
    text-align: center;
    /* width: 20px; */
    /* background: #0466FF; */
    /* padding: 20px; */
    padding: 23px 0px;
    color: #ffffff;
  }
  .login-btn {
    background: #0466ff;
    width: 10px;
    height: 55px;
    text-align: center;
    padding: 15px 45px;
    font-size: 16px;
    border-radius: 11px;
    cursor: pointer;
    display: flex;
    vertical-align: middle;
    align-items: center;
    margin: auto;
    justify-content: center;
  }
  .sign-up-box {
    display: flex;
    justify-content: center;
  }
  .sing-up {
    color: #0466ff;
    padding-left: 2px;
    padding-bottom: 50px;
    cursor: pointer;
  }
  .forogot-password-text {
    color: #585858;
    text-align: center;
    font-size: 16px;
    padding-top: 5px;
    width: 87%;
    padding: 18px 20px 0 84px;
}
.border-box {
    border: 1px solid #E6E7EA;
    align-self: stretch;
}
.sign-up-box {
 
    padding-top: 25px;
}
  
.signup-main .login-form-container
{
  padding: 50px 30px 50px 30px;
}
.signup-main .login-form-internal .form-group {
  margin-bottom: 25px;
}
.forgot-password-btn {
    width: 21% !important;
    padding: 0 !important;
}