.event-info-details-ctn {
  overflow: visible;
  width: 100%;
}
#sea-event-info-name {
  text-wrap: wrap;
}
.event-info-inner-ctn {
  display: flex;
}
#products {
  background-color: #1b182b;
  //margin-top: 7em;
  padding-top: 3em;
}

body {
  background-color: #2c293c !important;
  overscroll-behavior: none;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}
.product_left,
.product_filter {
  background-color: #211e32;
  border: 1px solid #2c293c;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 14px;
}

.testing #footer {
  display: none;
}

.product_left h3,
.ticket_plat h3 {
  color: #a992ff;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 0;
}

.product_left img {
  width: auto;
}

#products .container {
  width: 100%;
  // padding-left: 5% !important;
  // padding-right: 5% !important;
  max-width: 100vw;
}

.product_left h2 {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 0;
}

.t2,
.t1 {
  width: 85px;
}

.time {
  margin-left: 2%;
}

.product_left p {
  margin-top: 5px;
  margin-bottom: 5px;
}

.product_left ul {
  padding: 0;
  margin: 0;
}

.product_left li {
  list-style: none;
  border-bottom: 1px solid #2c293c;
  margin-bottom: 5px;
  display: flex;
  margin-top: 5px;
}

.product_left li:last-child {
  border-bottom: none;
}

.ticket_left {
  width: 80%;
}

.ticket_left h5 {
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 7px;
}

.ticket_left p {
  color: #a992ff;
  font-size: 14px;
}

.tick_price {
  width: 20%;
}

.tick_price h6 {
  color: #fff;
  background-color: #272438;
  border: 1px solid #333043;
  padding: 12px 0px;
  border-radius: 5px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  margin-top: 5px;
}

.tick_price h6 span {
  color: #7b7794;
}

.product_filter h1 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 20px;
}

#products select {
  border-radius: 9px;
  border: 1px solid #302d40;
  background: #272438;
  color: #7b7794;
  font-size: 13px;
  font-weight: 600;
  width: 100%;
  padding: 14px 10px;
}

.product_filter lable {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

#products button {
  background-color: #7d5fd2;
  color: #fff;
  // padding: 15px;
  // width: 100%;
  border: 1px solid #7d5fd2;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 600;
  padding: 3px 4px 3px 4px !important;
}

.product_filter ul {
  display: flex;
  flex-wrap: wrap;
}

.product_filter ul li {
  color: #7b7794;
  background-color: #272438;
  border: 1px solid #302d40;
  border-radius: 7px;
  height: 50px;
  width: 66.5px;
  text-align: center;
  padding: 14px;
  font-size: 16px;
  font-weight: 600;
  margin-right: 3.2%;
  margin-bottom: 0px;
  margin-top: 10px;
}

.product_filter ul li:hover,
.product_filter ul li:focus {
  background: linear-gradient(135deg, #a78cf2 0%, #7d5fd2 100%), #272438;
  color: white;
  border: 1px solid #a78cf2;
  cursor: pointer;
}

.product_filter ul .active {
  background: linear-gradient(135deg, #a78cf2 0%, #7d5fd2 100%), #272438;
  color: white;
  border: 1px solid #a78cf2;
}

.ticket_plat {
  padding: 10px;
}

.ticket_plat .img_tick {
  width: 100%;
}

.ticket_plat h3,
.ticket_plat h3 i {
  color: #7b7794;
}

.product_left h3 i,
.ticket_plat h3 i {
  padding-left: 3px;
}

@media (max-width: 767px) {
  .time {
    margin-top: 0 !important;
  }

  #list-ctn {
    margin-top: 30px;
  }

  #products {
    //margin-top:30px !important;
  }

  .navbar-brand {
    width: 100% !important;
    float: none !important;
    line-height: 0px !important;
  }

  #products .container {
    width: 100%;
    padding-left: 0% !important;
    padding-right: 0% !important;
    max-width: 100%;
  }
}

@media (max-width: 1000px) {
  .float-right {
    float: right;
    padding-right: 15px;
  }

  .login {
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .navbar-brand {
    margin-left: 0 !important;
  }

  .navigation.fixed-top .navbar-collapse {
    padding: 10px !important;
  }

  .navbar-brand {
    height: auto !important;
    margin-bottom: 5px;
  }

  #products {
    margin-top: 0;
    padding-top: 0;
  }
}

@media (max-width: 1330px) {
  .navbar-brand {
    width: 23%;
  }
}

div#map-list-holder,
div#venue-ticket-list {
  background-color: #1b182b !important;
}

tr.Sea-TicketRow.venue-ticket-list-row.venue-ticket-list-row-height.venue-ticket-list-row-js.venue-ticket-list-mark-col-align-top {
  background-color: #211e32 !important;
  .venue-ticket-list-section-row-text {
    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
td.venue-ticket-list-section-qty-col {
  color: #fff !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
span.venue-ticket-list-tg-row {
  color: #a992ff !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
span.venue-ticket-list-quantity.venue-ticket-list-quantity-js {
  color: #7b7794 !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.venue-ticket-list-row {
  border-bottom: 1px solid #2c293c;
}
legend#sea-precheckout-qty-selection-text,
legend.filters-type-label.sea-filter-text {
  color: #fff;
}

button.slick-next.cm-right-arrow.slick-arrow,
button.slick-next.cm-right-arrow.slick-arrow.slick-disabled,
button.slick-prev.cm-left-arrow.slick-arrow.slick-disabled,
button.slick-prev.cm-left-arrow.slick-arrow {
  width: 20px !important;
  background-color: 'none' !important;
  padding: 0px !important;
  font-size: 10px !important;
}

button.sea-accessible-button.sea-ticket-list-default-cta.sea-ticket-list-default-cta-js {
  border-radius: 6px;
  border: 1px solid #333043 !important;
  background: #272438 !important;
  padding: 10px 5px;
  margin-right: 10px;
}

.loader {
  border: 5px solid #7b7794;
  border-radius: 50%;
  border-top: 5px solid #a992ff;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}

button#sea-quantity-warning-modal-close,
button#event-info-guarantee-close {
  width: 25px !important;
  height: 25px !important;
  background-color: '#211e32' !important;
  padding: 0px !important;
  font-size: 16px !important;
}

button#sort-label,
div#sort-cntr {
  border-radius: 0px !important;
  border: 'none' !important;
  button {
    border-radius: 0px !important;
    background-color: #fff !important;
    color: #333043 !important;
  }
}

.filters-qty-filter.filters-qty-filter-cnt.sea-hidden-radio-buttons {
  .btn {
    // background-color: #a78cf2, #7d5fd2;
    margin: 10px;
    color: #7b7794;
    width: 65px;
    height: 40px;
    border-radius: 9px;
    border: 1px solid #302d40;
    background: #272438;
    font-size: 15px;
    font-weight: 600;
    padding: 8px 0px;
  }
}
label.btn.sea-btn.qty-filter-opt-label-js.active {
  // background-color: #a78cf2, #7d5fd2;
  margin: 10px;
  color: #fff;
  width: 65px;
  height: 40px;
  border: 1px solid #7d5fd2 !important;
  border-radius: 9px;
  background: linear-gradient(135deg, #a78cf2 0%, #7d5fd2 100%), #272438;
  font-size: 15px;
  font-weight: 600;
  padding: 8px 0px;
}

div#sea-filterCard-parent {
  border: 1px solid #2c293c;
  background: #211e32;
  border-radius: 10px;
}

input#price-filter-min,
input#price-filter-max {
  border-radius: 9px;
  border: 1px solid #302d40;
  background: #272438;
  width: 127px;
  height: 40px;
  flex-shrink: 0;
  color: #7b7794;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

div#sort-cntr {
  border-radius: 9px !important;
  border-color: #302d40 !important;
  background-color: #272438 !important;
  height: 40px;
  button {
    background-color: #272438 !important;
    border-color: #302d40 !important;
    color: #7b7794 !important;
  }
  button#sort-label {
    span#sort-type-label,
    span.sort-label-arrow.cm-down-arrow {
      color: #7b7794 !important;
    }
  }
}

div#sea-filterCardTitle {
  color: #fff;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sea-filterCard-header,
legend.filters-type-label.sea-filter-text {
  border-color: #2c293c !important;
}

button#sea-filters-back-to-list {
  display: none !important;
}

.slick-track {
  li.slick-slide {
    margin: 10px;
    color: #7b7794;
    width: 65px;
    height: 40px;
    border-radius: 9px;
    border: 1px solid #302d40;
    background: #272438;
    font-size: 15px;
    font-weight: 600;
    padding: 8px 0px;
  }
  li.slick-slide.slick-current.slick-active.sea-selected,
  li.slick-slide.sea-selected {
    margin: 10px;
    color: #fff;
    width: 65px;
    height: 40px;
    border: 1px solid #7d5fd2 !important;
    border-radius: 9px;
    background: linear-gradient(135deg, #a78cf2 0%, #7d5fd2 100%), #272438;
    font-size: 15px;
    font-weight: 600;
    padding: 8px 0px;
  }
}
button#pre-checkout-back-to-list {
  background-color: #272438 !important;
  border-color: #272438;
}
div#precheckout-parent,
div#event-info-area {
  background-color: #1b182b !important;
}

div#sea-inventory-slider-with-filterBtn {
  background-color: #211e32 !important;
}

.event-info-place {
  color: #7b7794;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: '8px';
}

.event-info-date {
  border-radius: 3px;
  background: rgba(169, 146, 255, 0.2);
  .event-info-date-date.event-info-date-loc,
  .event-info-date-time.event-info-date-loc {
    color: #a992ff;
  }
}

button#sea-all-in-pricing-toggle-btn,
button.slider.round.sea-delivery-type-option-js,
button.slider.round.sea-delivery-type-option-js {
  background-color: #211e32 !important;
  border-color: #a78cf2 !important;
}
#sea-filterCard-wrapper .switch.active .slider:before {
  background-color: #a78cf2 !important;
}

.dropdown-default.sea-dropdown-default-js,
div#venue-map-zoom-controls,
div#venue-map-reset-ctn {
  background-color: #a992ff !important;
  border-color: #a992ff !important;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.event-info-disclaimer {
  display: none;
}
#tn-maps {
  overflow: hidden !important;
  padding: 0 !important;
}
.venue-ticket-list::-webkit-scrollbar {
  width: 5px !important;
}
.sea-tg-list-disclosure-link {
  color: #fff;
  span {
    color: #fff;
  }
}
.sea-map-branding-link {
  display: none !important;
}
.suggestions::-webkit-scrollbar {
  width: 4px !important;
}

.navbar-collapse {
  box-shadow: none !important;
}

/*Event results page css*/
.location-filter-box {
  display: flex;
  gap: 10px;
  .header-search-bar {
    width: 200px;
    font-size: 14px;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 7px;
  }
}

.location-filter-box .header-search-bar:focus {
  outline: none;
}

.filter-apply {
  color: #7b7794;
  padding: 0 10px 0 10px;
  font-size: 15px;
  border: 1px solid;
  border-radius: 4px;
  margin-right: 7px;
  font-weight: 500;
  margin-left: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.filter-clear {
  color: #7b7794;
  padding: 2px 10px 0 10px;
  font-size: 15px;
  border: 1px solid;
  border-radius: 4px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.seatics * {
  cursor: pointer;
}

@media (max-width: 550px) {
  .tags li {
    width: auto;
    min-width: 97px;
    padding: 5px 5px;
    margin-right: 4px;
  }

  .location-filter-box .header-search-bar {
    width: 131px;
    font-size: 12px;
    padding: 3px;
  }

  .filter-apply {
    margin-right: 3px;
    margin-left: 3px;
    padding: 0px 10px 0 10px;
    font-size: 13px;
  }

  .filter-clear {
    padding: 0px 10px 0 10px;
    font-size: 13px;
  }
}
#list {
  height: 100%;
  overflow: scroll;
}
